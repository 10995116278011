<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="editWarningUser">
            <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="left">
                <el-form-item label="预警名称" prop="name">
                    <el-input style="width: 300px" v-model="form.name" maxlength="20" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="预警类型" prop="alertRuleId">
                    <el-select v-model="form.alertRuleId " clearable placeholder="请选择" style="width: 300px" @change="warningTypeChange" :disabled="alertId?true:false">
                        <el-option
                            v-for="item in warningTypeDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="预警条件" prop="alertRuleTimeList">
                    <el-button type="primary" @click="addRuleTimeItem">添加时间段</el-button>
                    <div class="a-flex-rfsc a-mt-12" v-for="(item,index) in form.alertRuleTimeList" :key="index">
                        <el-select v-model="item.startTime" clearable placeholder="开始时间" class="input-size">
                            <el-option
                                v-for="(ite,idx) in options"
                                :key="idx"
                                :label="ite"
                                :value="ite">
                            </el-option>
                        </el-select>
                        <span class="a-plr-10">至</span>
                        <el-select v-model="item.endTime" clearable placeholder="结束时间" class="input-size" style="margin-right: 10px">
                            <el-option
                                v-for="(ite,idx) in options"
                                :key="idx"
                                :label="ite"
                                :value="ite">
                            </el-option>
                        </el-select>
                        <!-- 站点设备在线率 -->
                        <div class="a-flex-rfsc" v-if="form.alertRuleId==1"> 
                            <span>在线率低于</span>
                            <el-input style="width:100px;margin: 0 10px" maxlength="6" v-model="item.param1" @input="item.param1=item.param1.replace(/^\.+|[^\d]+/g,'')"></el-input>
                            <span>%</span>
                        </div>
                        <!-- 无效订单指标 -->
                        <div class="a-flex-rfsc" v-if="form.alertRuleId==2"> 
                            <span>，</span>
                            <el-input style="width:100px;margin: 0 10px" maxlength="6" v-model="item.param1" @input="item.param1=item.param1.replace(/^\.+|[^\d]+/g,'')"></el-input>
                            <span>分钟内，无效订单高于</span>
                            <el-input style="width:100px;margin: 0 10px" maxlength="6" v-model="item.param2" @input="item.param2=item.param2.replace(/^\.+|[^\d]+/g,'')"></el-input>
                            <span>%</span>
                        </div>
                        <!-- 单台设备失败订单 -->
                        <div class="a-flex-rfsc" v-if="form.alertRuleId==3"> 
                            <span>连续下单失败</span>
                            <el-input style="width:100px;margin: 0 10px" maxlength="6" v-model="item.param1" @input="item.param1=item.param1.replace(/^\.+|[^\d]+/g,'')"></el-input>
                            <span>次</span>
                        </div>
                        <!-- 订单指标 -->
                        <div class="a-flex-rfsc" v-if="form.alertRuleId==4"> 
                            <span>，</span>
                            <el-input style="width:100px;margin: 0 10px" maxlength="6" v-model="item.param1" @input="item.param1=item.param1.replace(/^\.+|[^\d]+/g,'')"></el-input>
                            <span>分钟内订单数少于</span>
                            <el-input style="width:100px;margin: 0 10px" maxlength="6" v-model="item.param2" @input="item.param2=item.param2.replace(/^\.+|[^\d]+/g,'')"></el-input>
                            <span>笔</span>
                        </div>
                        <!-- "采购设备合格率" -->
                        <div class="a-flex-rfsc" v-if="form.alertRuleId==6"> 
                            <span>合格率低于</span>
                            <el-input style="width:100px;margin: 0 10px" maxlength="6" v-model="item.param1" @input="item.param1=item.param1.replace(/^\.+|[^\d]+/g,'')"></el-input>
                            <span>%</span>
                        </div>
                        <!-- "支付结单超时预警" -->
                        <div class="a-flex-rfsc" v-if="form.alertRuleId==7"> 
                            <span>超过</span>
                            <el-input style="width:100px;margin: 0 10px" maxlength="6" v-model="item.param1" @input="item.param1=item.param1.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                            <span>小时未结单</span>
                        </div>
                        <!-- "带柜门订单结单超时预警" -->
                        <div class="a-flex-rfsc" v-if="form.alertRuleId==8"> 
                            <span>超过</span>
                            <el-input style="width:100px;margin: 0 10px" maxlength="6" v-model="item.param1" @input="item.param1=item.param1.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                            <span>小时未结单</span>
                        </div>
                        <!-- "电费项目电费占比高预警" -->
                        <div class="a-flex-rfsc" v-if="form.alertRuleId==10"> 
                            <span>电费项目占比高于</span>
                            <el-input style="width:150px;margin: 0 10px" maxlength="6" v-model="item.param1" @input="item.param1=item.param1.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                            <span>%</span>
                        </div>
                        <!-- "电表剩余费用预警" -->
                        <div class="a-flex-rfsc" v-if="form.alertRuleId==11"> 
                            <span>电表电费剩余预估低于</span>
                            <jd-input-price style="width:150px;margin: 0 10px" v-model="item.param1"></jd-input-price>
                            <span>元</span>
                        </div>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="form.alertRuleTimeList.length > 1">
                            <img src="../../assets/icon/option-del-circle.png" @click="deleteRuleTimeItem(index)" class="a-wh-16 a-mlr-12" />
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="添加" placement="top">
                            <img src="../../assets/icon/option-add-circle.png" @click="addRuleTimeItem" class="a-wh-16 a-mlr-12" />
                        </el-tooltip>
                    </div>
                </el-form-item>
                <el-form-item label="通知群组" prop="groupId">
                    <el-select v-model="form.groupId" multiple collapse-tags clearable placeholder="请选择" style="width: 300px">
                        <el-option
                            v-for="item in sendUserGroupDic"
                            :key="item.groupId"
                            :label="item.groupName"
                            :value="item.groupId">
                            <div class="a-flex-rsbc a-w-100">
                                <span>{{ item.groupName }}</span>
                                <!-- <el-button type="text" @click.stop="showGroupInfo(item)">详情</el-button> -->
                                <el-popover
                                    placement="right"
                                    width="620"
                                    trigger="hover">
                                    <el-table max-height="500" :data="item.groupInfo">
                                        <el-table-column min-width="200" property="date" label="通知范围">
                                            <template slot-scope="{ row }">
                                                <div class="groupUsersBox" v-if="row.isGlobal">
                                                    <span>全局</span>
                                                </div>
                                                <div class="groupUsersBox" v-else-if="row.isNational">
                                                    <span>全国</span>
                                                </div>
                                                <div class="groupUsersBox" v-else>
                                                    <span v-if="row.regionName">{{ row.regionName }}</span>
                                                    <span v-if="row.companyName">商户：{{ row.companyName }}</span>
                                                    <span v-if="row.incomeName">项目：{{ row.incomeName }}</span>
                                                    <span v-if="row.stationName">站点：{{ row.stationName }}</span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column min-width="380" property="name" label="通知人员">
                                            <template slot-scope="{ row }">
                                                <div class="a-flex-rfsc a-flex-wrap">
                                                    <span class="stationTag" v-for="(item,index) in row.groupUsers" :key="index">
                                                        {{ item.userName }} {{ item.mobile }}（{{ item.companyName }}）
                                                    </span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <el-button slot="reference" type="text" style="margin-right: -40px;">详情</el-button>
                                </el-popover>
                            </div>
                            
                        </el-option>
                        <div class="a-flex-rcc">
                            <el-button type="text" v-if="showMore" @click="showMoreGroups">更多群组</el-button>
                            <el-button type="text" v-else disabled style="color: #C0C4CC">没有更多了</el-button>
                        </div>
                    </el-select>
                </el-form-item>
                <el-form-item label="通知人" prop="independentPerson">
                    <div class="a-flex-rfsc">
                        <el-input
                            clearable 
                            style="width: 300px;margin-right: 12px;" 
                            v-model="phone" 
                            maxlength="20" 
                            placeholder="请输入通知人手机号" 
                            @keyup.enter.native="addUser"></el-input>
                        <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="addUser">添加</el-button>
                    </div>
                    <div class="a-flex-rfsc a-flex-wrap a-mt-20" style="width: 800px;">
                        <div class="a-flex-rfsc send-user" v-for="(item,index) in form.independentPerson" :key="index">
                            <span class="send-user-name">{{ item.userName }} {{ item.mobile }}（{{ item.companyName }}）</span>
                            <!-- <span class="send-user-line"></span> -->
                            <i class="el-icon-close a-c-white" @click="removeUser(index)"></i>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </el-card>
        <choose-user ref="chooseUser" @success="handleChooseUser"></choose-user>
    </div>
</template>

<script>
    import chooseUser from './child/chooseUser.vue';
    export default {
        components:{
            chooseUser
        },
        data() {
            var checkGroupIds = (rule, value, callback) => {
                if(!this.form.groupId.length && !this.form.independentPerson.length){
                    callback(new Error('请选择通知群组或添加自定义通知人员'))
                }else{
                    callback()
                }
            };
            var checkUserIds = (rule, value, callback) => {
                if(!this.form.groupId.length && !this.form.independentPerson.length){
                    callback(new Error('请选择通知群组或添加自定义通知人员'))
                }else{
                    callback()
                }
            };
            var checkRuleTime = async (rule, value, callback) => {
                let errorText = await this.checkRules(this.form.alertRuleTimeList)
                if(errorText){
                    callback(new Error(errorText))
                }else{
                    callback()
                }
            };
            
            return {
                alertId: '',
                sendUserData: [],
                form: {
                    name: '',
                    alertRuleId: '',
                    alertRuleTimeList: [],
                    companyId: '',
                    groupId: [],
                    independentPerson: []
                },
                rules: {
                    name: [{required: true, message:'请输入预警名称', trigger: 'blur'}],
                    alertRuleId: [{required: true, message:'请选择预警类型', trigger: 'change'}],
                    alertRuleTimeList: [{ validator: checkRuleTime, trigger: 'change' }],
                    groupId: [{ validator: checkGroupIds, trigger: 'change' }],
                    independentPerson: [{ validator: checkUserIds, trigger: 'change' }]
                },
                warningTypeDic: [],
                sendUserGroupDic: [],
                options: [],
                phone: '',
                showMore: true,
                pageNum: 1
            };
        },
        created () {
            this.$getDic('alertRuleType','select').then(res=>{ 
                this.warningTypeDic = res; 
                this.form.alertRuleId = this.warningTypeDic[0].value
                this.addRuleTimeItem()
            })
            this.getGroups()
            this.alertId = this.$route.query.id?this.$route.query.id:''
            if(this.alertId) {
                this.getWarningInfo()
            }
        },
        computed: {
            
        },
        mounted () {
            this.options = []
            for (let index = 0; index < 24; index++) {
                if(index<10){
                    this.options.push('0' + index + ':00')
                }else{
                    this.options.push(index + ':00')
                }
            }
        },
        methods: {
            // 预警详情
            getWarningInfo () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.warningDetails,
                    method: "post",
                    params: {
                        id: this.alertId
                    },
                })
                .then(res=>{
                    let datas = res.result.data
                    let independentPerson = []
                    datas.userList.map(item=>{
                        item.groupUsers.map(ite=>{
                            independentPerson.push({
                                alertGroupUserId: ite.alertGroupUserId,
                                mobile: ite.mobile,
                                userId: ite.userId,
                                userName: ite.userName,
                                companyName: ite.companyName,
                                companyId: ite.companyId,
                                isNational: item.isNational,
                                isGlobal: item.isGlobal
                            })
                        })
                    })
                    this.form = {
                        name: datas.name,
                        alertRuleId: JSON.stringify(datas.alertRuleId),
                        alertRuleTimeList: datas.alertRuleTimeList,
                        groupId: datas.groupId,
                        independentPerson: independentPerson
                    }
                    if(this.form.alertRuleId == 7 || this.form.alertRuleId == 8){
                        this.form.alertRuleTimeList = this.form.alertRuleTimeList.map(item=>{
                            return {
                                ...item,
                                param1: Number((item.param1/60).toFixed(0))
                            }
                        })
                    }
                    if(this.form.alertRuleId == 11){
                        this.form.alertRuleTimeList = this.form.alertRuleTimeList.map(item=>{
                            return {
                                ...item,
                                param1: Number((item.param1/100).toFixed(0))
                            }
                        })
                    }
                    
                })
                .catch(err=>{
                    console.log(err);
                })
            },
            // 添加自定义通知人员
            addUser () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.checkUser,
                    method: "post",
                    params: {
                        mobile: this.phone
                    },
                })
                .then(res=>{
                    if (res.result.data && res.result.data.length) {
                        let user
                        if(res.result.data && res.result.data.length > 1){
                            this.$refs['chooseUser'].tableData = res.result.data
                            this.$refs['chooseUser'].dialogVisible = true
                            return
                        }else{
                            user = res.result.data[0]
                        }
                        let userItem = this.form.independentPerson.find(res=>{
                            return res.mobile == user.mobile && res.companyId == user.companyId
                        })
                        if(userItem){
                            this.$message.error('已添加该用户')
                            return
                        }
                        this.form.independentPerson.push({
                            mobile: user.mobile,
                            userId: user.userId,
                            userName: user.userName,
                            companyId: user.companyId,
                            companyName: user.companyName,
                            isNational: 0,
                            isGlobal: 1,
                        })
                        this.phone = ''
                    } else {
                        this.$confirm('未查询到用户，是否去添加用户？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$router.push({
                                path: '/staffManage/staff-add'
                            })
                        }).catch(_=>{})
                    }
                })
                .catch(err=>{

                })
            },
            // 选择商户用户
            handleChooseUser (datas) {
                datas.map(item=>{
                    this.form.independentPerson.push({
                        mobile: item.mobile,
                        userId: item.userId,
                        userName: item.userName,
                        companyId: item.companyId,
                        companyName: item.companyName,
                        isNational: 0,
                        isGlobal: 1,
                    })
                })
                this.form.independentPerson = this.uniqueArr(this.form.independentPerson)
                this.phone = ''
            },
            // 删除自定义通知人员
            removeUser (index) {
                this.form.independentPerson.splice(index,1)
            },
            showGroupInfo (item) {
                // this.$refs['sendUser'].drawerVisible = true
            },
            showMoreGroups () {
                this.pageNum++
                this.getGroups()
            },
            // 获取群组列表
            getGroups () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.warningUserGroupPage,
                    method: "post",
                    params: {
                        pageSize: 100,
                        pageNum: this.pageNum
                    },
                })
                .then(res=>{
                    if (res.result.data) {
                        if(res.result.data.list && res.result.data.list.length){
                            this.sendUserGroupDic = this.sendUserGroupDic.concat(res.result.data.list)
                        }else{
                            this.showMore = false
                        }
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            },
            // 预警类型改变
            warningTypeChange () {
                this.form.alertRuleTimeList = []
                this.addRuleTimeItem()
            },
            // 添加预警时间段
            addRuleTimeItem () {
                switch (this.form.alertRuleId) {
                    case '1':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                            param1: ''
                        })
                        break;
                    case '2':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                            param1: '',
                            param2: ''
                        })
                        break;
                    case '3':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                            param1: ''
                        })
                        break;
                    case '4':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                            param1: '',
                            param2: ''
                        })
                        break;
                    case '5':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                        })
                        break;
                    case '6':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                            param1: ''
                        })
                        break;
                    case '7':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                            param1: ''
                        })
                        break;
                    case '8':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                            param1: ''
                        })
                        break;
                    case '9':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                        })
                        break;
                    case '10':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                            param1: ''
                        })
                        break;
                    case '11':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                            param1: ''
                        })
                        break;
                    case '12':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                        })
                        break;
                    case '13':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                        })
                        break;
                    case '14':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                        })
                        break;
                    case '15':
                        this.form.alertRuleTimeList.push({
                            startTime: '',
                            endTime: '',
                        })
                        break;
                    default:
                        break;
                }
            },
            // 删除预警时间段
            deleteRuleTimeItem (index) {
                this.form.alertRuleTimeList.splice(index,1)
            },
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$confirm('是否确认提交？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            let reqData
                            reqData = {
                                ...this.form,
                                groupId: this.form.groupId,
                                alertId: this.alertId,
                            }
                            if(this.form.alertRuleId == 7 || this.form.alertRuleId == 8) {
                                reqData.alertRuleTimeList = reqData.alertRuleTimeList.map(item=>{
                                    return {
                                        ...item,
                                        param1: Number((item.param1*60).toFixed(0))
                                    }
                                })
                            }
                            if(this.form.alertRuleId == 11) {
                                reqData.alertRuleTimeList = reqData.alertRuleTimeList.map(item=>{
                                    return {
                                        ...item,
                                        param1: Number((item.param1*100).toFixed(0))
                                    }
                                })
                            }
                            this.$Axios._post({
                                url: this.$Config.apiUrl.warningEdit,
                                method: "post",
                                params: reqData,
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.$router.back()
                                    this.$store.dispatch("delView", this.$route);
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        }).catch(_=>{ })
                    }
                })
            },
            // 时间段校验
            checkRules (datas) {
                return new Promise((resolve, reject) => {
                    if(datas.length && datas){
                        let noValid = [5,9,12,13,14,15]
                        for (let index = 0; index < datas.length; index++) {
                            const item = datas[index];
                            if(!item.startTime){
                                resolve('开始时间不能为空')
                                return
                            }
                            if(!item.endTime){
                                resolve('结束时间不能为空')
                                return
                            }
                            // this.form.alertRuleId != 5 && this.form.alertRuleId != 9 && this.form.alertRuleId != 12
                            if(!item.param1 && noValid.indexOf(this.form.alertRuleId) != -1){
                                resolve('请输入预警值')
                                return
                            }
                            if(!item.param2 && (this.form.alertRuleId == 2 || this.form.alertRuleId == 4)){
                                resolve('请输入预警值')
                                return
                            }
                            if(Number(item.param1)>100 && this.form.alertRuleId == 1){
                                resolve('预警值不能大于100%')
                                return
                            }
                            if(Number(item.param2)>100 && this.form.alertRuleId == 2){
                                resolve('预警值不能大于100%')
                                return
                            }
                        }
                        resolve()
                    }else{
                        resolve()
                    }
                })
            },
            cancelSubmit () {
                this.$confirm('是否确认返回？','温馨提示')
                .then(_=>{
                    this.$router.back()
                    this.$store.dispatch("delView", this.$route);
                })
                .catch(_=>{})
            },
            //数组去重
            uniqueArr (arr) {
                try{
                    for(var i=0; i<arr.length; i++){
                        for(var j=i+1; j<arr.length; j++){
                            if(arr[i].mobile==arr[j].mobile && arr[i].companyId==arr[j].companyId){         //第一个等同于第二个，splice方法删除第二个
                                arr.splice(j,1);
                                j--;
                            }
                        }
                    }
                    return arr;
                }catch(err){
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .editWarningUser{
        font-size: 14px;
        height: 100%;
        overflow-y: auto;
        
        .send-user{
            background: #53A8FF;
            color: #ffffff;
            border-radius: 4px;
            padding: 0 10px;
            margin: 0 10px 10px 0;
            .send-user-line{
                width: 1px;
                height: 16px;
                // background: #ffffff;
                margin: 0 5px;
            }
        }
    }
    /deep/ .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        // word-break: keep-all;
    }
    .groupUsersBox{
        display: flex;
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        width: 100%;
        height: 100%;
    }
</style>