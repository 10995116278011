<template>
    <div>
        <el-dialog
            title="选择范围"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <el-table
                :data="tableData"
                @selection-change="handleSelectionChange"
                max-height="500px"
                style="width: 100%">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column
                    label="姓名"
                    min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.userName }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="手机号"
                    min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.mobile }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="商户"
                    min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.companyName }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                tableData: [],
                selectData: []
            };
        },
        watch:{
   
        },
        methods:{
            handleSelectionChange (selection) {
                this.selectData = selection
            },
            submit () {
                this.dialogVisible = false
                this.$emit('success',this.selectData)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .rangeName{
        min-width: 100px;
    }
    .send-user{
        background: #53A8FF;
        color: #ffffff;
        border-radius: 4px;
        padding: 0 10px;
        margin: 0 10px 10px 0;
        .send-user-line{
            width: 1px;
            height: 16px;
            // background: #ffffff;
            margin: 0 5px;
        }
    }
</style>